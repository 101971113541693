<template>
  <div class="home">
    <button
      class="button field is-info is-light"
      @click="
        isCardModalActive = true
        model = {}
        selectedPartStocks = []
      "
    >
      <b-icon icon="plus"></b-icon>
      <span>Nova manutenção</span>
    </button>
    <b-table
      :data="allMaintenances"
      ref="table"
      paginated
      per-page="10"
      :striped="true"
      :debounce-search="1000"
      @details-open="(row) => $buefy.toast.open(`Visualizando os detalhes`)"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
    >
      <b-table-column
        field="date"
        label="Data"
        :searchable="true"
        sortable
        v-slot="props"
        >{{ $moment(props.row.date).format('DD/MM/YYYY') }}</b-table-column
      >

      <b-table-column
        field="type"
        label="Tipo"
        sortable
        :searchable="true"
        v-slot="props"
        >{{ props.row.maintenance_type }}</b-table-column
      >

      <b-table-column
        field="value"
        label="Valor"
        sortable
        :searchable="true"
        v-slot="props"
        >R$ {{ parseFloat(props.row.value).toFixed(2) }}</b-table-column
      >

      <b-table-column
        field="vehicle"
        label="Veículo"
        sortable
        :searchable="true"
        v-slot="props"
        >{{ props.row.vehicle.plate }}</b-table-column
      >

      <b-table-column
        field="approval_date"
        label="Aprovado"
        sortable
        :searchable="true"
        v-slot="props"
        >{{
          props.row.approval_date
            ? 'SIM (' +
              $moment(props.row.approval_date).format('DD/MM/YYYY') +
              ')'
            : 'NÃO'
        }}</b-table-column
      >

      <b-table-column v-slot="props" label="Opções">
        <button
          class="button is-small is-success"
          @click.prevent="onEdit(props.row); approvalMaintenance()"
        >
          <b-icon icon="check" size="is-small"></b-icon>
        </button>
        <button
          class="button is-small is-light"
          @click.prevent="
            onEdit(props.row)
            isCardModalActive = true
          "
        >
          <b-icon icon="pen" size="is-small"></b-icon>
        </button>
        <button
          class="button is-small is-danger"
          @click.prevent="onDelete(props.row.id)"
        >
          <b-icon icon="delete" size="is-small"></b-icon>
        </button>
      </b-table-column>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <p>{{ props.row }}</p>
              <!-- <p>
                <strong>Título:</strong>
                <small> {{ props.row.title.toUpperCase() }}</small>
                <br />
                <strong>Quilometragem (periodicidade):</strong>
                <small> {{ props.row.odometer }} Km</small>
                <br />
                <strong>Veículos:</strong>
                <br />
                <span
                  v-for="(vehicle, index) of props.row.vehicles"
                  :key="index"
                >
                  - {{ vehicle.plate }}<br />
                </span>
                <br />
                <strong>Serviços Autorizados:</strong>
                <br />
                <span
                  v-for="(authorizedService, index) of props.row
                    .authorized_services"
                  :key="index"
                >
                  - {{ authorizedService.name }}<br />
                </span>
              </p> -->
            </div>
          </div>
        </article>
      </template>
    </b-table>

    <b-modal v-model="isCardModalActive" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <b-field label="Data da manutenção *">
              <b-datepicker
                ref="datepicker"
                inline
                v-model="model.date"
                placeholder="Selecione uma data"
              ></b-datepicker>
            </b-field>
            <b-field label="Tipo de manutenção">
              <b-select
                expanded
                placeholder="Selecione o tipo de manutenção"
                required
                v-model="model.maintenance_type"
              >
                <option
                  v-for="(item, index) in ['Preventiva', 'Corretiva']"
                  :key="index"
                  :value="item"
                >
                  {{ item }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Veículo">
              <model-list-select
                :list="allVehicles"
                :custom-text="vehicleCustomOption"
                placeholder="Selecione um veículo"
                required
                v-model="model.vehicle_id"
                option-value="id"
                @input="selectVehicle"
              >
              </model-list-select>
            </b-field>
            <b-field label="Fornecedor">
              <model-list-select
                :list="allSuppliers"
                :custom-text="supplierCustomOption"
                placeholder="Selecione um fornecedor/oficina"
                required
                v-model="model.supplier_id"
                option-value="id"
              >
              </model-list-select>
            </b-field>
            <b-field label="Nota Fiscal">
              <model-list-select
                :list="allInvoices"
                :custom-text="invoiceCustomOption"
                placeholder="Selecione uma nota fiscal"
                required
                v-model="model.invoice_id"
                option-value="id"
              >
              </model-list-select>
            </b-field>
            <b-field
              v-if="model.maintenance_type === 'Preventiva'"
              label="Agendamento"
            >
              <model-list-select
                :list="allMaintenanceSchedulings"
                :custom-text="maintenanceSchedulingCustomOption"
                placeholder="Selecione um agendamento"
                required
                v-model="model.maintenance_scheduling_id"
                option-value="id"
              >
              </model-list-select>
            </b-field>
            <b-field label="Serviços (a serem executados)">
              <multiselect
                v-model="model.authorizedServices"
                :options="allAuthorizedServices"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Selecione os serviços"
                label="name"
                track-by="id"
                :preselect-first="false"
              ></multiselect>
            </b-field>
            <b-field label="Valor total *">
              <b-input
                required
                v-money="moneyFormat"
                v-model="model.value"
              ></b-input>
            </b-field>
            <b-field label="Status">
              <b-select
                expanded
                placeholder="Selecione um status"
                required
                v-model="model.maintenance_status"
              >
                <option
                  v-for="(item, index) in [
                    'Agendada',
                    'Em Andamento',
                    'Finalizada',
                  ]"
                  :key="index"
                  :value="item"
                >
                  {{ item }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Peças (em estoque)">
              <button
                class="button field is-info is-light"
                @click="addPartStock"
              >
                <b-icon icon="plus"></b-icon>
                <span>Adicionar nova peça</span>
              </button>
            </b-field>
            <b-field
              v-for="(partStock, index) of selectedPartStocks"
              :key="index"
            >
              <b-collapse
                class="card"
                animation="slide"
                :open="isOpen == index"
                @open="isOpen = index"
              >
                <div
                  slot="trigger"
                  slot-scope="props"
                  class="card-header"
                  role="button"
                >
                  <p class="card-header-title">
                    {{
                      getPartStockByID(selectedPartStocks[index].id)
                        ? getPartStockByID(
                            selectedPartStocks[index].id
                          ).name.toUpperCase()
                        : 'Nenhuma peça selecionada'
                    }}
                  </p>
                  <a class="card-header-icon">
                    <b-icon :icon="props.open ? 'menu-down' : 'menu-up'">
                    </b-icon>
                  </a>
                </div>
                <div class="card-content">
                  <div class="content">
                    <b-field label="Peça">
                      <model-list-select
                        :list="allPartStocks"
                        :custom-text="partStockCustomOption"
                        placeholder="Selecione uma peça"
                        required
                        v-model="selectedPartStocks[index].id"
                        option-value="id"
                      >
                      </model-list-select>
                    </b-field>
                    <b-field label="Quantidade *">
                      <b-input
                        required
                        type="number"
                        min="0"
                        v-model="selectedPartStocks[index].quantity"
                      ></b-input>
                    </b-field>
                  </div>
                </div>
                <footer class="card-footer">
                  <a @click="removePartStock(index)" class="card-footer-item"
                    >Remover</a
                  >
                </footer>
              </b-collapse>
            </b-field>
            <b-button type="button field is-info" class="mt-10" @click="save"
              >Salvar</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import service from '../features/maintenances/store/service'
import Maintenance from '../models/maintenance'

export default {
  name: 'Maintenances',
  data() {
    return {
      model: Maintenance.model,
      allAuthorizedServicesTemp: [],
      selectedAuthorizedServices: [],
      allFilterMaintenanceSchedulings: [],
      selectedPartStocks: [],
      isModalVisible: false,
      isOpen: false,
      showDetailIcon: false,
      isCardModalActive: false,
      moneyFormat: {
        prefix: 'R$ ',
        sstatefix: '',
        precision: 2,
        masked: false,
      },
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...mapState('maintenanceSchedulings', ['allMaintenanceSchedulings']),
    ...mapState('maintenances', ['allMaintenances']),
    ...mapState('suppliers', ['allSuppliers']),
    ...mapState('authorizedServices', ['allAuthorizedServices']),
    ...mapState('vehicles', ['allVehicles']),
    ...mapState('partStocks', ['allPartStocks']),
    ...mapState('invoices', ['allInvoices']),
  },
  methods: {
    ...mapActions('maintenanceSchedulings', ['getAllMaintenanceSchedulings']),
    ...mapActions('maintenances', ['getAllMaintenances']),
    ...mapActions('suppliers', ['getAllSuppliers']),
    ...mapActions('authorizedServices', ['getAllAuthorizedServices']),
    ...mapActions('vehicles', ['getAllVehicles']),
    ...mapActions('partStocks', ['getAllPartStocks']),
    ...mapActions('invoices', ['getAllInvoices']),
    authorizedServiceCustomOption(item) {
      return item.name
    },
    partStockCustomOption(item) {
      return item.name
    },
    invoiceCustomOption(item) {
      return `${item.number} (R$ ${parseFloat(item.value).toFixed(2)})`
    },
    maintenanceSchedulingCustomOption(item) {
      return `${item.title} (${item.odometer} KM)`
    },
    vehicleCustomOption(item) {
      return `${item.plate} (${item.vehicle_model.model})`
    },
    supplierCustomOption(item) {
      return item.company_name
    },
    getPartStockByID(id) {
      return this.allPartStocks.find((x) => x.id === id)
    },
    getQuantityPartStockByID(id) {
      if (!id) return 0
      return this.allPartStocks.find((x) => x.id === id)
    },
    getAuthorizedServiceByID(id) {
      return this.allAuthorizedServices.find((x) => x.id === id)
    },
    selectVehicle(vehicle) {
      this.allFilterMaintenanceSchedulings = []
      if (vehicle) {
        for (const scheduling of this.allMaintenanceSchedulings) {
          if (scheduling.vehicles.find((x) => x.id === vehicle)) {
            this.allFilterMaintenanceSchedulings.push(scheduling)
          }
        }
      }
    },
    async init() {
      await this.getAllMaintenances()
      await this.getAllSuppliers()
      await this.getAllMaintenanceSchedulings()
      await this.getAllAuthorizedServices()
      await this.getAllVehicles()
      await this.getAllPartStocks()
      await this.getAllInvoices()

      console.log('invoices', this.allVehicles)

      this.allAuthorizedServicesTemp = this.allAuthorizedServices.map((x) => {
        return {
          ...x,
          price: parseFloat(x.price).toFixed(2),
        }
      })
    },
    approvalMaintenance() {
      this.model.approval_date = new Date().toISOString()

      this.save()
    },
    async save() {
      const maintenance = { ...this.model }

      maintenance.authorized_services = maintenance.authorizedServices.map(
        (x) => {
          return x.id
        }
      )

      maintenance.part_stocks = this.selectedPartStocks

      maintenance.date = this.$moment(maintenance.date).format('YYYY-MM-DD')

      maintenance.value = maintenance.value.replace('R$ ', '')
      maintenance.value = maintenance.value.replace(',', '')

      console.log('maintenance ', maintenance)

      try {
        const save = maintenance.id
          ? await service.updateMaintenance(maintenance)
          : await service.saveMaintenance(maintenance)

        await this.$success('Manutenção')
        await location.reload(true)
      } catch (error) {
        console.log('error', error)
        this.$error(
          error.response.data.message || error.response.data[0].message
        )
      }
    },
    async onDelete(id) {
      try {
        const result = await this.$ask('Você deseja remover a manutenção?')

        if (result.isConfirmed) {
          await service.deleteMaintenance(id)

          await this.$delete('Manutenção')
          location.reload(true)
        }
      } catch (error) {
        this.$error(error.response.data.message)
      }
    },
    onEdit(item) {
      this.model = { ...item }
      this.model.date = new Date(item.date)
      this.model.value = parseFloat(item.value).toFixed(2)
      this.model.authorizedServices = item.authorized_services

      console.log(item)

      this.selectedPartStocks = item.part_stocks.map((x) => {
        return {
          ...x,
          quantity: x.pivot.quantity,
        }
      })
    },
    addAuthorizedService() {
      this.selectedAuthorizedServices.push({
        id: null,
        value: 0,
      })
    },
    removeAuthorizedService(index) {
      this.selectedAuthorizedServices.splice(index, 1)
    },
    addPartStock() {
      this.selectedPartStocks.push({
        id: null,
        quantity: 0,
      })
    },
    removePartStock(index) {
      this.selectedPartStocks.splice(index, 1)
    },
  },
}
</script>

<style scoped></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>