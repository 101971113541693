module.exports = {
  model: {
    id: '',
    standstill_date: null,
    forecast_date: null,
    approval_date: null,
    association: [],
    maintenance_type: '',
    maintenance_status: '',
    identified_problems: [],
    authorized_services: [],
    vehicle: '',
    supplier: '',
    maintenance_scheduling: '',
  },
  schema: {
    fields: [
      {
        type: 'vueMultiSelect',
        label: 'Tipo',
        model: 'maintenance_type',
        required: true,
        multiSelect: false,
        placeholder: 'Selecione o tipo de manutenção',
        selectOptions: {
          multiple: false,
          trackBy: 'id',
          label: 'name',
          selectLabel: '',
          searchable: true,
          taggable: true,
          closeOnSelect: true,
          hideSelected: true,
        },
        values: [],
      },
      {
        type: 'vueMultiSelect',
        label: 'Veículo',
        model: 'vehicle',
        required: true,
        multiSelect: false,
        placeholder: 'Selecione um veículo',
        selectOptions: {
          multiple: false,
          trackBy: 'id',
          label: 'plate',
          selectLabel: '',
          searchable: true,
          taggable: true,
          closeOnSelect: true,
          hideSelected: true,
        },
        values: [],
      },
      {
        type: 'vueMultiSelect',
        label: 'Agendamentos',
        model: 'maintenance_scheduling',
        placeholder: 'Selecione os agendamentos',
        multiSelect: true,
        visible: false,
        selectOptions: {
          multiple: true,
          trackBy: 'id',
          label: 'title',
          selectLabel: '',
          searchable: true,
          taggable: true,
          closeOnSelect: true,
          hideSelected: true,
        },
        values: [],
      },
      {
        type: 'input',
        inputType: 'number',
        label: 'Odômetro',
        model: 'odometer',
        placeholder: 'Informe o odômetro',
      },
      {
        type: 'input',
        inputType: 'number',
        label: 'Horímetro',
        model: 'hour_meter',
        placeholder: 'Informe o horímetro',
      },
      {
        type: 'input',
        inputType: 'date',
        label: 'Data de Paralisação',
        model: 'standstill_date',
        required: true,
      },
      {
        type: 'input',
        inputType: 'date',
        label: 'Previsão de Liberação',
        model: 'forecast_date',
      },
      {
        type: 'vueMultiSelect',
        label: 'Problemas identificados',
        model: 'identified_problems',
        multiSelect: true,
        placeholder: 'Selecione os problemas identificados',
        required: true,
        selectOptions: {
          multiple: true,
          trackBy: 'id',
          label: 'name',
          selectLabel: '',
          searchable: true,
          taggable: true,
          closeOnSelect: false,
          hideSelected: true,
        },
        values: [],
      },
      {
        type: 'custom-text-alert',
      },
      {
        type: 'authorized-service',
        label: 'Serviços',
        model: 'authorized_services',
        labelButton: 'Novo serviço',
        options: [],
        required: false,
      },
      {
        type: 'custom-association',
        label: 'Notas Fiscais',
        model: 'associations',
        typeInput: 'currency-input',
        labelButton: 'Associar nova Nota Fiscal',
        options: [],
        required: false,
      },
      {
        type: 'vueMultiSelect',
        label: 'Fornecedor',
        model: 'supplier',
        required: true,
        multiSelect: false,
        placeholder: 'Selecione um fornecedor',
        selectOptions: {
          multiple: false,
          trackBy: 'id',
          label: 'company_name',
          selectLabel: '',
          searchable: true,
          taggable: true,
          closeOnSelect: true,
          hideSelected: true,
        },
        values: [],
      },
      {
        type: 'vueMultiSelect',
        label: 'Status',
        model: 'maintenance_status',
        required: true,
        multiSelect: false,
        placeholder: 'Selecione o status da manutenção',
        selectOptions: {
          multiple: false,
          trackBy: 'id',
          label: 'name',
          selectLabel: '',
          searchable: true,
          taggable: true,
          closeOnSelect: true,
          hideSelected: true,
        },
        values: [],
      },
      {
        model: 'approval_date',
        visible: true,
        buttons: [
          {
            classes: 'btn-location',
            label: 'Aprovar manutenção',
            onclick: function(model, field) {
              model.approval_date = new Date().toISOString()

              console.log(model)
            },
          },
        ],
      },
    ],
  },
  columns: [
    {
      key: 'vehicle',
      label: 'Placa',
      filter: true,
      relation: true,
      relationKey: 'plate',
    },
    {
      key: 'supplier',
      label: 'Fornecedor',
      filter: true,
      relation: true,
      relationKey: 'company_name',
    },
    // { key: 'forecast', label: 'Data NF', filter: true },
    // { key: 'invoice_number', label: 'Nº NF', filter: true },
    {
      key: 'standstill_date',
      label: 'Data de paralisação',
      dateMask: true,
      filter: true,
    },
    { key: 'approval_date', label: 'Data de Aprovação', dateMask: true },
    { key: 'created_at', label: 'Data OS', dateMask: true, filter: true },
    { key: 'work_order_number', label: 'Nº OS', filter: true },
    {
      key: 'maintenance_status',
      label: 'Status da manutenção',
      relation: true,
      relationKey: 'name',
    },
    {
      key: 'maintenance_type',
      label: 'Tipo de manutenção',
      relation: true,
      relationKey: 'name',
    },
  ],
  viewDataTypes: [
    {
      key: 'vehicle',
      label: 'Placa do Veículo',
      relation: true,
      relationKey: 'plate',
    },
    {
      key: 'vehicle',
      label: 'CR',
      relation: true,
      relationKey: 'cost_center_id',
    },
    // { key: 'vehicle', label: 'vehicle_type', relation: true, relationKey: 'vehicle_type' },
    // { key: 'maintenance_scheduling', label: 'Agendamento', relation: true, relationKey: 'title' },
    // { key: 'supplier', label: 'Fornecedor', relation: true, relationKey: 'company_name' },
    { key: 'totalValue', label: 'Custo total', currencyMask: true },
    {
      key: 'maintenance_status',
      label: 'Status da manutenção',
      relation: true,
      relationKey: 'name',
    },
    {
      key: 'maintenance_type',
      label: 'Tipo de manutenção',
      relation: true,
      relationKey: 'name',
    },
    { key: 'standstill_date', label: 'Data de paralisação', dateMask: true },
    { key: 'number_work_order', label: 'Número da Manutenção' },
    { key: 'forecast_date', label: 'Previsão de liberação', dateMask: true },
    { key: 'created_at', label: 'Data da OS', dateMask: true },
    { key: 'approval_date', label: 'Data de Aprovação', dateMask: true },
    // { key: 'identified_problems', label: 'Problemas Identificados', array: true, arrayKey: 'name', },
    {
      key: 'authorized_services',
      label: 'Serviços Autorizados',
      array: true,
      multiple: true,
      keys: [
        { label: 'Nome', key: 'name' },
        { label: 'Status', key: 'status' },
        { label: 'Preço', key: 'price', currencyMask: true },
      ],
    },
    {
      key: 'invoices',
      label: 'Notas Fiscais',
      array: true,
      multiple: true,
      keys: [
        { label: 'Número', key: 'number' },
        { label: 'Data da Nota', key: 'date' },
        { label: 'Tipo da Nota', key: 'type' },
        { label: 'Mês de Gasto', key: 'spending_month' },
        { label: 'Valor associado', key: 'value', currencyMask: true },
      ],
    },
  ],
}
